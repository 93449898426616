<template>
	<div class="container">
		<div class="header">
			<a @click="goback"><img src="../assets/images/back.png" alt=""></a> {{ title }}</div>
		<div class="blian22"></div>
		<!-- 筛选 -->
		<div>
			<div class="search-tab">
				<dl>
					<dt>类型：</dt>
					<dd style="white-space: pre-line;">
						<span :class="types == item.tagId ? 'active' : ''" v-for="(item,index) in typesArr" :key="index" @click="changeTab(2,item.tagId)">{{item.tagName}}</span>
					</dd>
				</dl>
			</div>
			<div  v-model="types">
				<van-list class="home-list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="getSearch(types)">
					<ul>
						<li v-for="(item,index) in listArr" :key="index">
							<!-- 图书 -->
							<router-link v-if="types == 1" :to="{path:'./demandcont?id=' + item.bookId}">
								<!-- ❀(fhq) -->
								<!-- <img :src="item.bookPic" alt="" class="zip"> -->
								<img v-if="item.bookPic != '' && item.bookPic != null" :src="item.bookPic" alt="" class="zip">
								<img v-else src="../assets/images/default-book.png" alt="" class="zip">
								<p class="ellipsis_two">{{item.bookName}}</p>
							</router-link>
							<!-- end -->
							<!-- 资源 -->
							<router-link v-else-if ="types == 2" :to="{path:'./teachercont?id=' + item.prepareId}">
								<!-- && item.facePic != null  -->
								<img v-if="item.facePic.indexOf('null') == -1 && item.facePic != null " :src="item.facePic" alt="" class="zip">
								<img v-else src="../assets/images/default-book.png" alt="" class="zip">
								<p class="ellipsis_two">{{item.lessonName}}</p>
							</router-link>
							<!-- end -->
						</li>
					</ul>
				</van-list>
			</div>
		</div>
	</div>
</template>
<script>
	// import {
	// 	tags,
	// 	getBooks,
	// 	getBooksNew,
	// 	getVersionListByApiBooks
	// } from "@/assets/js/api";
	import { getBooksNewByTitel,getPreparationsByTitel } from "@/assets/js/api";
	import { search } from 'vant';
	export default {
		name: "english",
		data() {
			return {
				title:'搜索内容',
				// gradeArr: ["图书","内容"], //类型
				typesArr:[{
					tagId:"1",
					tagName:"图书"
				},{
					tagId:"2",
					tagName:"资源"
				}],

				types: 1,

				// 列表
				listArr: [],
				type: '',
				//下拉上拉
				// 列表
				listArr: [],
				page: 1,
				limit: 9,

				loading: false,
				finished: false,
			};
		},
		methods: {
			goback() {
				this.$router.back()
				// 返回的时候 清空选中
				this.types = ''
			},
			changeTab: function(type, index) {
			   if (type == 2) {
			   	this.types = this.types == index ? '' : index;
			   	this.subject = '';
			   }
			   /* 切换滚动状态*/
			   this.loading = false
			   this.finished = false
				this.page = 1;
				this.listArr = [];
				this.getSearch(index);
			},
			getSearch(type){
				if(type == 1){
					// 获取图书
					this.getBooks()
				}else if(type == 2){
					// 获取资料
					this.getResource()
				}
			},
			//获取图书列表
			getBooks() {
				let params = {
					book_name:this.bookName,
					page: this.page,
					limit: this.limit
				}
				getBooksNewByTitel(params).then(res => {
					// console.log(res)
					this.loading = false;
					if (res.code == 0) {
						if (res.list.length > 0) {
							if (this.page == 1) {
								this.listArr = res.list;
							} else {
								this.listArr = this.listArr.concat(res.list);
							}
							if (res.list.length < 9) {
								this.finished = true;
							} else {
								// this.finished = false;
								this.page++;
							}
						} else {
							this.finished = true;
						}
					}
				});
			},
			/* 查询资料 */
			getResource() {
				let params = {
					lesson_name:this.bookName,
					page: this.page,
					limit: this.limit
				}
				getPreparationsByTitel(params).then(res => {
					this.loading = false;
					if (res.code == 0) {
						if (res.pres.length > 0) {
							if (this.page == 1) {
								this.listArr = res.pres;
								// console.log(this.listArr)
							} else {
								this.listArr = this.listArr.concat(res.pres);
							}
							if (res.pres.length < 9) {
								this.finished = true;
							} else {
								// this.finished = false;
								this.page++;
							}
						} else {
							this.finished = true;
						}
					}
				});
			},
		},
		watch: {
		},
		activated() {
			
		},
		mounted() {
			this.bookName = this.$route.query.titleName
			this.getSearch(1);
		}
	};
</script>
