<template>
	<div class="container" style="min-height: 100vh;">
		<div class="header">
			<a @click="goback"><img src="../assets/images/back.png" alt=""></a> {{ title }}</div>
		<div class="blian22"></div>
		<!-- 筛选 -->
		<div class="scrollBox" style="overflow: scroll;">
			<div class="search-tab">
				<!-- <dl>
					<dt>学段：</dt>
					<dd>
						<span :class="learnSection == item.tagId ? 'active' : ''" v-for="(item,index) in learnSectionArr" :key="index" @click="changeTab(1,item.tagId)">{{item.tagName}}</span>
					</dd>
				</dl> -->
				<dl>
					<dt>年级：</dt>
					<dd style="white-space: pre-line;">
						<span :class="grade == item.tagId ? 'active' : ''" v-for="(item,index) in gradeArr" :key="index" @click="changeTab(2,item.tagId)">{{item.tagName}}</span>
					</dd>
				</dl>
				<!-- v-if="grade" -->
				<dl>
					<dt>科目：</dt>
					<dd style="white-space: pre-line;">
						<span :class="subject == item.tagId ? 'active' : ''" v-for="(item,index) in subjectArr" :key="index" @click="changeTab(3,item.tagId)">{{item.tagName}}</span>
					</dd>
				</dl>
				<dl>
					<dt>版本：</dt>
					<dd style="white-space: pre-line;">
						<span :class="edition == item.tagId ? 'active' : ''" v-for="(item,index) in editionArr" :key="index" @click="changeTab(4,item.tagId)">{{item.tagName}}</span>
					</dd>
					<dt v-if="!ifdropDown && ifshowDown" @click="godropDown">
						<img src="../assets/images/down1.png" alt="" class="zip downImg">
					</dt>
					<dt v-else-if="ifdropDown && ifshowDown" @click="godropDown">
						<img src="../assets/images/up1.png" alt="" class="zip downImg">
					</dt>
				</dl>
				<dl>
					<dt>册别：</dt>
					<dd>
						<span :class="volumes == item.tagId ? 'active' : ''" v-for="(item,index) in volumesArr" :key="index" @click="changeTab(5,item.tagId,item.tagName)">{{item.tagName}}</span>
					</dd>
				</dl>
			</div>
			<van-list class="home-list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="getBooks">
				<ul>
					<li v-for="(item,index) in listArr" :key="index">
						<router-link :to="{path:'./demandcont?id=' + item.bookId}">
							<!-- ❀(fhq) -->
							<!-- <img :src="item.bookPic" alt="" class="zip"> -->
							<img v-if="item.bookPic != '' && item.bookPic != null " :src="item.bookPic" alt="" class="zip">
							<img v-else src="../assets/images/default-book.png"  alt="" class="zip">
							<!-- <img src="../assets/images/favicon.png" style="width: 100px; height: 100px;" alt="" class="zip"> -->
							<p class="ellipsis_two">{{item.bookName}}</p>
						</router-link>
					</li>
				</ul>
			</van-list> 
		</div>
	</div>
</template>
<script>
	import { tags, getBooks, getBooksNew, getVersionListByApiBooks, tagsNew} from "@/assets/js/api";
	import { List } from 'vant';
	export default {
		name: "demand",
		data() {
			return {
				title: '',
				learnSectionArr:[],//学段
				subjectArr: [], //科目
				editionArr: [], //版本
				editionAll: [], //版本
				editionArrTree: [], //版本
				gradeArr: [], //年级
				volumesArr: [], //册别
				
				
				edition: '',
				grade: '',
				learnSection: 0,
				volumes: '',
				subject: '',
				// 册别名称
				volumesName: '',
				
				// edition: '',
				// subject: '',
				// 列表
				listArr: [],
				type: '',
				/* 下拉的显示与隐藏 */
				ifshowDown:false,
				ifdropDown:false,

				page: 1,
				limit: 10,
				//下拉上拉
				page: 1,
				limit: 9,
				loading: false,
				finished: false,
			};
		},
		methods: {
			goback() {
				this.$router.go(-1)
				// ❀ (fhq)
				sessionStorage.removeItem("learnSection")
				sessionStorage.removeItem("grade")
				sessionStorage.removeItem("subject")
				sessionStorage.removeItem("edition")
				sessionStorage.removeItem("volumes")
			},
			// changeTab: function(type, index) {
			// 	if(type == 3) {
			// 		this.subject = this.subject == index ? '' : index;
			// 	} else if(type == 4) {
			// 		this.edition = this.edition == index ? '' : index;
			// 	}
			// 	this.page = 1;
			// 	this.listArr = [];
			// 	this.getBooks()
			// },
			// ❀(fhq)
			changeTab: function(type, index,tagName) {
				if(type == 2) {
					this.grade = this.grade == index ? '' : index;
					this.getTags(3, this.grade);
					sessionStorage.setItem("grade", this.grade);
					this.subject = '';
				} else if(type == 3) {
					this.subject = this.subject == index ? '' : index;
					sessionStorage.setItem("subject", this.subject);
				} else if(type == 4) {
					this.edition = this.edition == index ? '' : index;
					sessionStorage.setItem("edition", this.edition);
				} else if(type == 5) {
					this.volumes = this.volumes == index ? '' : index;
					sessionStorage.setItem("volumes", this.volumes);
					// this.volumesName = tagName
					this.volumesName = this.volumesName == tagName ? '' : tagName;
				}
				this.page = 1;
				this.listArr = [];
				this.finished = false
				this.getBooks()
				// 查询版本
				this.getEditions()
			},
			// 下拉点击事件
			godropDown(){
				// 当点击下拉的时候 将三个变为全部
				if(!this.ifdropDown){
					this.ifdropDown = true
					this.editionArr = this.editionAll
				}else if(this.ifdropDown){
					// 当点击收起的时候 将全部变为三个
					this.ifdropDown = false
					this.editionArr = this.editionArrTree 
				}
			},
			// 获取标签
			getTags(tagType, upperTagId) {
				// ❀ (fhq)
				// tags({
				// 	upperTagId: upperTagId,
				// 	tagType: tagType
				// }).then(res => {
				// 	if(res.code == 0) {
				// 		if(tagType == 3) {
				// 			this.subjectArr = res.tags;
				// 		} else if(tagType == 4) {
				// 			this.editionArr = res.tags;
				// 		}
				// 	}
				// });
				/* 
				 if(tagType == 4) {
				 	this.editionArr = res.tags;
				 } else 
				 */
				// tags({
				// 	upperTagId: upperTagId,
				// 	tagType: tagType
				// }).then(res => {
				// 	if(res.code == 0) {
				// 		if(tagType == 2) {
				// 			this.gradeArr = res.tags;
				// 		} else if(tagType == 3) {
				// 			this.subjectArr = res.tags;
				// 		} else if(tagType == 5) {
				// 			this.volumesArr = res.tags;
				// 		}
				// 	}
				// });
				tagsNew({
					bookTypeId:this.$route.query.bookType,
					upperTagId: upperTagId,
					tagType: tagType
				}).then(res => {
					if(res.code == 0) {
						if(tagType == 2) {
							this.gradeArr = res.tags;
						} else if(tagType == 3) {
							this.subjectArr = res.tags;
						} else if(tagType == 5) {
							this.volumesArr = res.tags;
						}
					}
				});
			},
			//获取图书列表
			getBooks() {
				// alert(this.learnSection)
				// let params = {
				// 	learnSection: localStorage.getItem('learnSection'),
				// 	volumes: localStorage.getItem('volumes'),
				// 	grade: localStorage.getItem('grade'),
				// 	bookType: this.type,
				// 	subject: this.subject,
				// 	edition: this.edition,
				// 	page: this.page,
				// 	limit: this.limit
				// }
				
				/* 查询 "全一册" 的数组 */
				var AllvolumeObj = this.findWhatYouWant("全一册")
				/* 全一册的id */
				var volumesOne = ""
				// console.log(AllvolumeObj);
				if(this.volumesName != '' && this.volumesName != "全一册"){
					
					if( AllvolumeObj != null && AllvolumeObj != ""){
						volumesOne = AllvolumeObj.tagId
					}
				}
				// ❀ (fhq)
				let params = {
					learnSection: this.learnSection,
					volumes: this.volumes,
					volumesOne:volumesOne,
					grade: this.grade,
					subject: this.subject,
					edition: this.edition,
					bookType: this.type,
					page: this.page,
					limit: this.limit
				}
				// console.log(params)
				// getBooks(params).then(res => {
				// 	this.loading = false;
				// 	if(res.code == 0) {
				// 		if(res.books.length > 0) {
				// 			if(this.page == 1) {
				// 				this.listArr = res.books;
				// 			} else {
				// 				this.listArr = this.listArr.concat(res.books);
				// 			}
				// 			if(res.books.length < 9) {
				// 				this.finished = true;
				// 			} else {
				// 				this.page++;
				// 			}
				// 		} else {
				// 			this.finished = true;
				// 		}
				// 	} else {}
				// });
				getBooksNew(params).then(res => {
					this.loading = false;
					if (res.code == 0) {
						if (res.list.length > 0) {
							// console.log(res)
							if (res.currentPage == 1) {
								this.listArr = res.list;
							} else {
								this.listArr = this.listArr.concat(res.list);
							}
							if (res.list.length < 9) {
								this.finished = true;
							} else {
								this.page++;
							}
							
						} else {
							this.finished = true;
						}
					}
				});
			},
			// 获取版本
			getEditions() {
				var AllvolumeObj = this.findWhatYouWant("全一册")
				/* 全一册的id */
				var volumesOne = ""
				if(this.volumesName != '' && this.volumesName != "全一册"){
					if( AllvolumeObj != null && AllvolumeObj != ""){
						volumesOne = AllvolumeObj.tagId
						// console.log(volumesOne);
					}
				}
				/* fhq(❀) */
				let params = {
					learnSection: 0,
					volumes: this.volumes,
					volumesOne:volumesOne,
					grade: this.grade,
					subject: this.subject,
					edition: this.edition,
					bookType: this.type
				}
				getVersionListByApiBooks(params).then(res => {
					this.loading = false;
					// console.log(res)
					if(res.tags){
						// this.editionArr = res.tags;
						// 当所有版本大于3个的时候 开始下拉
						if(res.tags.length>3){
							this.ifshowDown = true
							// 所有的版本
							let edition = res.tags
							// 截取前三个版本
							edition = edition.slice(0,3)
							// console.log(edition)
							// 存储所有的版本
							this.editionAll = res.tags;
							// 存储前三个版本
							this.editionArr = edition;
							this.editionArrTree = edition;
						}else{
							this.ifshowDown = false
							this.editionArr = res.tags;
						}
					}else{
						this.edition = ''
					}
					
				});
			},
			// 查找符合条件的对象
			findObj(volumesArr, value) {
				return volumesArr.find((item, index, array) => {
					return Object.is(item.tagName, value)
				});
			},
			findWhatYouWant(value) {
				return this.findObj(this.volumesArr, value);
			}
		},
		watch: {
			type() {
				// this.page = 1;
				// this.edition = '';
				// this.subject = '';
				// this.getBooks();
			}
		},
		activated() {
			this.type = this.$route.query.bookType;
			this.title = this.$route.query.titleName;
			// console.log(this.listArr)
		},
		mounted() {
			
			this.type = this.$route.query.bookType;
			this.title = this.$route.query.titleName;
			// this.getTags(3, localStorage.getItem('grade'));
			// this.getTags(1);
			
			// ❀ (fhq)
			// if(sessionStorage.getItem("learnSection")){
			// 	this.learnSection = sessionStorage.getItem("learnSection")
			// 	this.getTags(2, this.learnSection);
			// }
			if(sessionStorage.getItem("grade")){
				this.grade = sessionStorage.getItem("grade")
				this.getTags(3, this.grade);
			}
			if(sessionStorage.getItem("subject")){
				this.subject = sessionStorage.getItem("subject")
			}
			if(sessionStorage.getItem("edition")){
				this.edition = sessionStorage.getItem("edition")
			}
			if(sessionStorage.getItem("volumes")){
				this.volumes = sessionStorage.getItem("volumes")
			}
			
			this.getTags(2);
			this.getTags(3);
			this.getTags(4);
			this.getTags(5);
			this.getEditions()
		}
	}
</script>